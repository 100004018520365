import { Text, Paper, ActionIcon, Group } from '@mantine/core';
import { Card, Image, Badge, Button, Modal } from '@mantine/core';
import posthog from 'posthog-js';
import {useEffect, useState} from 'react';

import {  Collapse, Box, MantineNumberSize } from '@mantine/core';
import { useDisclosure, useToggle } from '@mantine/hooks';
import styles from "../styles/Search.module.css";
import { BuyFromMerchant } from '../merchants/MerchantButton';
import { CheckoutButton } from '../checkout/CheckoutButton';

import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { IoCloseSharp } from "react-icons/io5";
import { FaCartShopping } from "react-icons/fa6";
import { Send, Refresh, Share, Clipboard } from 'tabler-icons-react';
import { ProductButtons } from '../utils/ProductButtons';
import { MoreInfoTabs } from './moreinfo/MoreInfoTabs';

export function ProductPreview(props) {

    const context = useContext(PageContext)
    const STARTING_MESSAGE = "Find similar products to this"
    const [query, setQuery] = useState(STARTING_MESSAGE);


    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
    }, []);

    function handleChange(event: any) {
        setQuery(event.target.value)
    }

    function handleKeyDown(event: any) {
        if (event.key === 'Enter') {
            props.handleSubmit();
            props.updateQuery("")
        }
    }

    function handleSubmit(event: any) {
        let sentQuery = query + "\nReferenced product: " + props.title + " Price: " + props.price
        props.handleProductSpecificSearch(sentQuery);
        setQuery("")
        props.toggle()
    }


    // console.log(props)

    function handleOverlayClick(event) {
      if (props.opened && !event.target.closest('#product-card')) {
          console.log("otggle off")
          props.toggle();
      }
    }


        return (
                <>
                <style>
                  {`
                    .product-preview {
                      position: absolute;
                      left: 0;
                      right: 0;
                      z-index: 9999;
                    }
                  `}
                </style>
                <div className="flex min-h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0 product-preview overflow-y-scroll" style={{zIndex: 9999, background: 'rgba(0, 0, 0, 0.5)'}} hidden={!props.opened} onClick={handleOverlayClick}  >
                      <div id="product-card" className="relative transform overflow-y-scroll rounded-xl text-left shadow-xl transition-all sm:my-8 mt-20 sm:w-full sm:max-w-lg">
                        {/* add x in top right corner */}
                        <div className="absolute top-0 right-0">
                            <button className="flex items-center p-4 z-[10000]" onClick={() => {props.toggle()}}>
                              <IoCloseSharp className="h-6 w-6 text-gray-500" />
                            </button>
                        </div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 overflow-y-scroll">
                          {/* <div className="aspect-h-1 aspect-w-1 w-full overflow-y-scroll rounded-xl aspect-none h-60 p-1 ">
                            {props.images.length > 0 && <img src={props.images[0]} className="mx-auto" alt="Image not found"  />}
                          </div> */}
                          <div className="bg-contain bg-no-repeat bg-center p-8 m-2 h-36 sm:h-60 md:h-60 lg:h-60 rounded-xl" style={{ backgroundImage: `url(${props.image})` }}>
                                {/* <img src={props.image} className="mx-auto" alt="Image not found" /> */}
                            </div>
                          <div className="p-3">
                            {/* <hr className="h-px mb-8 bg-gray-200 border-0 dark:bg-gray-500" /> */}
                            <h3 className="mt-1 text-lg text-sm text-gray-900">
                                {props.title}
                            </h3>
                            {/* <h3 className="mt-4 text-lg text-gray-600 flex justify-between">
                                <div className='pr-2'>
                                    {props.price}
                                </div>
                                <div>
                                    { props.rating && `★ ${props.rating}`}
                                </div>
                            </h3> */}

                            {/* <div className="mt-4">
                                <h4 className="text-gray-600">Description:</h4>
                                <p className="text-gray-800">{props.description}</p>
                            </div> */}
                            {/* <div className="mt-4">
                                <h4 className="text-gray-600">Category:</h4>
                                <ul className="list-disc list-inside text-gray-800">
                                    {props.category && props.category.map((cat, index) => (
                                        <li key={index}>{cat.name}</li>
                                    ))}
                                </ul>
                            </div> */}
                            {/* <div className="mt-4">
                                <h4 className="text-gray-600">Delivery:</h4>
                                <ul className="list-disc list-inside text-gray-800">
                                    {props.delivery && props.delivery.map((del, index) => (
                                        <li key={index}>{del.type} - {del.date.by}</li>
                                    ))}
                                </ul>
                            </div> */}
                            {/* <div className="mt-4">
                                <h4 className="text-gray-600">Bullets:</h4>
                                <ul className="list-disc list-inside text-gray-800">
                                    {props.bullet_points && props.bullet_points.split('\n').map((bullet, index) => (
                                        <li key={index}>{bullet}</li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                          {/* <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                              <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                              </svg>
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                              <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Deactivate account</h3>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.</p>
                              </div>
                            </div>
                          </div> */}
                        <MoreInfoTabs redditRefs={props.redditRefs} videos={props.videos} gl={props.gl}  productInfo={props.productInfo} searchQuery={props.searchQuery} askedQuestions={props.askedQuestions} />

                        {props.merchants && props.merchants.map((merchant, index) => (
                                <BuyFromMerchant
                                  key={index}
                                  detailPageURL={merchant.links.original}
                                  link={merchant.links.tagged}
                                  price={merchant.price || null}
                                  toggleOpened={props.opened}
                                  border={true}

                                />
                            ))}

                            <BuyFromMerchant
                              key="current_merchant"
                              detailPageURL={props.detailPageURL}
                              rating={props.rating}
                              reviews_count={props.reviews_count}
                              link={props.link}
                              price={props.price}
                              toggleOpened={props.opened}
                              border={true}

                            />

                            <CheckoutButton
                              key="checkout_claros"
                              detailPageURL={props.detailPageURL}
                              link={props.link}
                              session={props.session}
                              forceCheckout={props.forceCheckout}
                              productInfo={props.productInfo}
                              setCheckoutProduct={props.setCheckoutProduct}
                              toggleOpened={props.opened}
                              toggle={props.toggle}
                              border={true}

                            />


                        <div className='mt-4 flex space-y-2 rounded-xl max-w-[700px] w-full mx-auto ' style={{ justifyContent: 'center' }}>
                          <div className='mx-0 grow flex-1'>
                          <div className="flex space-x-2 rounded-xl border-2 border-indigo-600 bg-gray-50 p-2 sm:p-3 " x-data="{ show: true }" x-clock>
                              <textarea
                              value={query}
                              onChange={handleChange}
                              onKeyDown={handleKeyDown}
                              placeholder = {STARTING_MESSAGE}
                              className=" resize-none grow bg-transparent text-sm sm:text-lg text-gray-900 focus:outline-none "
                              />
                              <div className="justify-end flex ">

                                  <ActionIcon variant="transparent" onClick={handleSubmit} className='mx-1'>
                                      <Send
                                          style={{ color: context.buttonColor }}
                                          size={40}
                                          color="#3949AB"
                                      />
                                  </ActionIcon>
                              </div>
                          </div>
                          </div>
                        </div>
                        </div>
                      </div>
                </div>
                </>
        )
}

