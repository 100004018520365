let API_URL = process.env.NEXT_PUBLIC_API_URL

export async function streamResults(endpoint, body, setter, val, setLoading) {
    let responseIngredients = null
    responseIngredients = await fetch(`${API_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
    });
  
    const reader = responseIngredients.body.getReader();
    const decoder = new TextDecoder();
    let lastMessage = '';
    let done = false;
    let history = val
    setLoading(false)
    while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        if (value) {
            const chunkValue = decoder.decode(value, { stream: true }).trim();
            // Split the chunkValue by newline to get individual JSON strings
            const jsonStrings = chunkValue.split('\n');
            jsonStrings.forEach(jsonString => {
                try {
                    if (jsonString) { // Make sure the jsonString is not empty
                        // console.log("jsonString", jsonString)
                        const jsonData = JSON.parse(jsonString);
                        lastMessage += jsonData.data;
                        console.log("last message stream", lastMessage)
                        // Append accumulated chat message to the UI
  
                        setter( history + lastMessage);
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error, 'from jsonString:', jsonString);
                }
            });
        }
    }
  }