import { ProductButtons } from "../utils/ProductButtons";
import clsx from 'clsx';
import { useContext } from 'react';
import { PageContext } from '../../contexts/pageContext';
import posthog from "posthog-js";
import { FaLink } from "react-icons/fa";
import getSymbolFromCurrency from 'currency-symbol-map'

let NUMBER_PATTERN = /\d+(\.\d+)?|\.\d+/

const extractNumber = (str, pattern) => {
    const match = str.match(pattern);
    return match ? match[0] : null;
};

const displayRatingOrReview = (data) => {
    if (typeof data === 'string') {
        return extractNumber(data, NUMBER_PATTERN);
    } else if (typeof data === 'number') {
        return data.toLocaleString();
    } else {
        return null;
    }
};

const getMerchantName = (url) => {
    try {
        const parsedUrl = new URL(url, window.location.origin);
        const hostname = parsedUrl.hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/, '');
        const domainParts = hostname.split('.');
        return domainParts.length > 1 ? domainParts[0] : hostname; // Assuming the merchant name is the first part of the domain
    } catch (error) {
        console.error("Invalid URL:", url, error);
        return "";
    }
};




export const BuyFromMerchant = (props) => {


    const context = useContext(PageContext)
    const hostname = props.detailPageURL ? getMerchantName(props.detailPageURL) : '';

    const ClickMerchantLink = (link) => {
        posthog.capture('product click', { product: link , click_type: 'see more', modal: props.toggleOpened || false, merchant_button: true})
        window.open(link, '_blank')
    }


    return (
        <div className={`flex flex-row items-center justify-between bg-white p-2 my-2 ${props.border ? 'border border-gray-200 shadow-sm rounded-lg' : ''}`}>
            <div className="flex items-center space-x-2">
                <img src={`https://www.google.com/s2/favicons?domain=${props.detailPageURL}&sz=64`} alt="favicon" className='w-6 h-6' />
                <div className="flex flex-col">
                    <span className={`text-sm ${props.classic ? '' : 'font-medium'}`}>{hostname}</span>
                    {props.rating && (
                        <span className="text-yellow-400">
                            ★ {displayRatingOrReview(props.rating)}
                        </span>
                    )}
                    {props.reviews_count && (
                        <span className="text-xs text-gray-500">
                            ({displayRatingOrReview(props.reviews_count)} reviews)
                        </span>
                    )}
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <span className={`text-md md:text-lg ${props.classic ? '' : 'font-medium'}  text-gray-800`}>
                    { props.price && typeof props.price === 'object' ? `${getSymbolFromCurrency(props.price.currency)}${(props.price.amount / 100).toFixed(2)}` : props.price}
                </span>
                <button onClick={()=> ClickMerchantLink(props.link)} rel="noopener noreferrer" className={`${props.classic ? 'bg-cardBg text-textGray hover:bg-zinc-200' : 'bg-indigo-500 hover:bg-indigo-600 text-white font-bold'} py-2 px-4 rounded inline-block text-sm`}>
                <FaLink className="inline mr-1" />  visit
                </button>
            </div>
        </div>
    )
}
