import React, { useEffect, useState, useContext } from 'react';
import { streamResults } from './utils';
import { PageContext } from '../../../contexts/pageContext';
import markdownToHTML from '../../utils/CarouselMarkdowntoHTML';

const SkeletonText = () => {
  return (
    <div className="flex flex-col w-full pt-2">
      <div className="w-11/12 h-3 bg-zinc-300 rounded animate-pulse mb-3 ml-auto"></div>
      <div className="w-full h-3 bg-zinc-300 rounded animate-pulse mb-3"></div>
      <div className="w-full h-3 bg-zinc-300 rounded animate-pulse mb-3"></div>
    </div>
  );
};

export const IngredientAnalysis = ({productInfo, analysis, setAnalysis}) => {
    const [loading, setLoading] = useState(false);
    const context = useContext(PageContext)

  useEffect(() => {
    const fetchData = async () => {
        let body = {"products": [productInfo], 'language': context.language };
        try {
          await streamResults("getIngredientInfo", body, setAnalysis, analysis, setLoading);
        } catch (error) {
          console.error("Failed to fetch table data:", error);
        }
    };
    if (analysis === ""){
      setLoading(true);
      fetchData();
  }
    fetchData();
  }, []);

    return (
        <div className='w-full'>
            <div className='flex justify-center items-center w-full'>
              {loading ? (
                  <SkeletonText />
              ) : (
                  <div dangerouslySetInnerHTML={{ __html: markdownToHTML(analysis) }} ></div>
              )}
            </div>
        </div>
    );
}
