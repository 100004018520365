const boldPattern = /\*\*([^*]+)\*\*/g;

const markdownToHTML = (markdown) => {

  // Convert bold text
  console.log("All Markdown", markdown)
  if (markdown) {
    markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Convert italic text
    markdown = markdown.replace(/\*(.*?)\*/g, '<i>$1</i>');

    // Convert inline code
    markdown = markdown.replace(/`(.*?)`/g, '<code>$1</code>');

      // Convert headers
    markdown = markdown.replace(/^# (.*$)/gim, '<h1>$1</h1>');
    markdown = markdown.replace(/^## (.*$)/gim, '<h2>$1</h2>');
    markdown = markdown.replace(/^### (.*$)/gim, '<h3>$1</h3>');
    markdown = markdown.replace(/^#### (.*$)/gim, '<h4>$1</h4>');
    markdown = markdown.replace(/^##### (.*$)/gim, '<h5>$1</h5>');
    markdown = markdown.replace(/^###### (.*$)/gim, '<h6>$1</h6>');

      // Convert links
    markdown = markdown.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank"><u>$1</u></a>');


    markdown = markdown.replace(/\n/g, '<div class="my-[15px] block"></div> ')

    // Identify and replace multiple consecutive divs
    var multipleDivPattern = /(<div class="my-\[15px\] block"><\/div>\s*){2,}/g;
    markdown = markdown.replace(multipleDivPattern, '<div class="my-[15px] block"></div>');
  }
  return markdown;
}

export default markdownToHTML;

