import React, { useEffect, useState, useContext } from 'react';
import { streamResults } from './utils';
import { PageContext } from '../../../contexts/pageContext';
import markdownToHTML from '../../utils/CarouselMarkdowntoHTML';

const SkeletonText = () => {
    return (
      <div className="flex flex-col w-full pt-2">
        <div className="w-11/12 h-3 bg-zinc-300 rounded animate-pulse mb-3 ml-auto"></div>
        <div className="w-full h-3 bg-zinc-300 rounded animate-pulse mb-3"></div>
        <div className="w-full h-3 bg-zinc-300 rounded animate-pulse mb-3"></div>
      </div>
    );
  };




export const ReviewSummary = ({productInfo, redditRefs, askedQuestions, searchQuery, gl, reviews, setReviews}) => {
    const [loading, setLoading] = useState(false);
    const context = useContext(PageContext)

    useEffect(() => {
        const fetchData = async () => {
              let body = {"product": productInfo, redditRefs, askedQuestions, searchQuery, 'language': context.language, "gl":gl }
              try {
                await streamResults("product/review", body, setReviews, reviews, setLoading);
              } catch (error) {
                console.error("Failed to fetch table data:", error);
              }
            }

        if (reviews === ""){
            setLoading(true);
            fetchData();
        }
    }, []);

    return (
        <div className='w-full'>
            <div className='flex justify-center items-center w-full'>
              {loading ? (
                  <SkeletonText />
              ) : (
                  <div dangerouslySetInnerHTML={{ __html: markdownToHTML(reviews) }} ></div>
              )}
            </div>
        </div>
    );
}
