import { useState } from 'react';
import { TextInput, Modal, Button, Group, Rating } from '@mantine/core';
import { useForm } from '@mantine/form';
import posthog from 'posthog-js';
import { FaCalendar, FaExternalLinkAlt } from "react-icons/fa";

export function FeedbackModal(props: any) {
    const [rating, setRating] = useState(0);

    function submitForm(values) {
        console.log(values)
        posthog.capture('feedback written', {
            'email': values.email,
            'feedback': values.feedback,
            'improvement': values.improvement, // Capture improvement feedback
            'rating': rating,
            'thread': 'https://claros.so/threads/' + props.sessionUUID
        });
        props.setOpened(false);
        return;
    }

    const form = useForm({
        initialValues: {
            email: '',
            feedback: '',
            improvement: '', // New field for improvement feedback
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) || value.length == 0 ? null : 'Invalid email'),
            feedback: (value) => (value.length == 0 ? 'Please Enter Feedback' : null),
            improvement: (value) => (value.length == 0 ? 'Please Enter What Needs to Be Better' : null), // Validation for improvement field
        },
    });

    return (
        <Modal
            opened={props.opened}
            onClose={() => props.setOpened(false)}
            size="sm"
            title="Help us Make Claros Better!"
        >
            <form onSubmit={form.onSubmit(submitForm)}>

                <div className='my-2 underline'>
                <a  href="https://calendly.com/claros-j74" target="_blank" rel="noopener noreferrer" className='flex items-center text-textMain font-sans'><div className='text-xs text-indigo-600 font-sans underline'>Let's chat!</div></a>
                </div>
                <TextInput label="Email (Optional)" placeholder="" size="xs" {...form.getInputProps('email')} className="font-sans text-Main" />
                <TextInput withAsterisk label="Did Claros help you?" placeholder="Yes, but I want a better product comparison!" size="xs" mt="xs" {...form.getInputProps('feedback')} className="font-sans text-Main rounded-lg" />
                <TextInput withAsterisk label="What needs to be better?" placeholder="Tell us what can be improved" size="xs" mt="xs" {...form.getInputProps('improvement')} className="font-sans text-Main" />

                <Rating
                    value={rating}
                    onChange={setRating}
                    className='my-[3%]'
                />

                <Group position="left" mt="md">
                <Button type="submit" className='bg-indigo-600 text-white'>Submit</Button>
                {/* <Button component="a" href="https://calendly.com/claros-j74" target="_blank" rel="noopener noreferrer" className='flex items-center bg-blue-500 text-white'><FaCalendar className="mr-2"/>Book a Call with Us <FaExternalLinkAlt className="ml-2"/> </Button> */}
                </Group>
            </form>
        </Modal>
    );
}
