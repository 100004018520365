import {Check, Ban} from 'tabler-icons-react';
import { Text, TextInput, ActionIcon, Container, Group, } from '@mantine/core';
import { AwesomeButton } from "react-awesome-button";
import React, { useState, useRef, useEffect } from 'react';
import posthog from 'posthog-js';
import { useContext } from 'react';
import { PageContext } from '../contexts/pageContext';
import { BetterButton } from './utils/Buttons';
import { Clipboard } from 'tabler-icons-react'
import { FaLink, FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { FaShare } from 'react-icons/fa';
import { IoIosShareAlt } from "react-icons/io";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { EllipsesButton } from './EllipsesButton';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../@/components/ui/dropdown-menu"


const languageMap = {
    'English': {
        sharebutton: 'Share',
        copied: 'Copied!',
        copyLink: 'Copy link',
    },
    'en-IN': {
        sharebutton: 'Share',
        copied: 'Copied!',
        copyLink: 'Copy link',
    },
    'Spanish': {
        sharebutton: 'Compartir',
        copied: 'Copiado!',
        copyLink: 'Copiar link',
    },
}

export function ShareButton(sessionId, isMobile) {
    const context = useContext(PageContext)
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const link = "https://claros.so/threads/" + sessionId.sessionId

    const dropdownRef = useRef(null);

    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggleDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div >
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
            <button className=" hover:bg-gray-100 rounded-xl cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm text-textGray hover:text-bluelogo  p-2 justify-right">
                <FaArrowUpFromBracket  size={17} className="" />
                {/* <p>Share</p> */}
            </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[9999] mt-1 rounded-xl shadow-sm rounded-xl border bg-bgwhite  p-2 sm:p-3 m-4">
                    <div onClick={(e) => {handleCopy();}} className="block px-4 py-2 hover:bg-gray-100 rounded-xl" style={{ color: copied ? context.buttonColor : 'inherit' }}>
                        <div className="flex items-center text-sm text-gray-700">
                            <FaLink style={{ color: context.buttonColor }} className="mr-2" />

                            {copied ? languageMap[context.language].copied : languageMap[context.language].copyLink}
                        </div>
                    </div>


                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-xl">
                        <div className="flex items-center">
                            <div className="mr-2">
                                <FaFacebookF style={{ color: context.buttonColor }} />
                            </div>
                        Facebook
                    </div>
                    </a>
                    <a href={`https://twitter.com/share?url=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-xl">
                        <div className="flex items-center">
                            <FaTwitter style={{ color: context.buttonColor }} className="mr-2" />
                            Twitter
                        </div>
                    </a>
                    <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-xl">
                        <div className="flex items-center">
                            <FaLinkedinIn style={{ color: context.buttonColor }} className="mr-2" />
                            LinkedIn
                        </div>
                    </a>
                    <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-xl">
                        <div className="flex items-center">
                            <FaWhatsapp style={{ color: context.buttonColor }} className="mr-2" />
                            WhatsApp
                        </div>
                    </a>

      </DropdownMenuContent>
    </DropdownMenu>
        </div>
        )
}

