import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useRouter } from 'next/router';
import { supabase_public } from '../lib/supabaseClient';
import posthog from 'posthog-js';

export default function AuthPrompt(props: any) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setError] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);

  const showCloseButton = props.showCloseButton || false;

  // Function to toggle the sign-up popup
  const toggleSignUp = () => {
      console.log("toggling")
      setShowSignUp(!showSignUp);
      setError('');
      setEmail('')
      setPassword('')
  };
  let redirectTo = `${window.location}`

  const resetPassword = () => {
    router.push('/accounts/forgot');
  };
  // const handleRedirect = (data, error) => {
  //   console.log("calling handle redirect")
  //   if (error) {
  //     setError(error.message);
  //   } else {
  //     window.location.reload(); // Reload the page on redirect
  //   }
  // };

  const router = useRouter();
  const handleGoogleLogin = async () => {
    console.log("redirect to", redirectTo)
    const { data, error } = await supabase_public.auth.signInWithOAuth({ provider: 'google', options: {redirectTo: redirectTo} });
    console.log(data)
    if (error) {
      console.error('Error signing in with Google:', error.message);
      setError(error.message);
    } else {
      posthog.capture('Google sign in', { 'email': data });
      console.log(data)
        //router.push('/dashboard');
    }
  };


  const CreateAccount = async (e) => {
    e.preventDefault();

    if (password.length < 7) {
        setError("Your password is less than 7 characters")
        return;

    }

    console.log("submitting Redirect to after account creation", redirectTo)
    const { data, error } = await supabase_public.auth.signUp({
        email: email,
        password: password,
        options: {
            emailRedirectTo: redirectTo
        }
    });
    if (error) {
        console.error('Error signing up:', error.message);
        setError("There are too many concurrent sign ups. Please try again later.")
    } else {
        posthog.capture('account created' , { 'email': email })



        router.push(`/accounts/emailconfirmation?email=${email}`, '_blank'); // Tell them to check email
    }
  }


  const SignIn = async (e) => {
    e.preventDefault();

    const { data, error } = await supabase_public.auth.signInWithPassword({
      email: email,
      password: password,
    });
    console.log(data)
    if (error) {
      console.error('Error signing up:', error.message);
      setError("Please re-enter your password.")
    }
    if (data && !error) {
      console.log("signed in")
      props.setShowAuthPrompt(false);
    }

  }

    if (showSignUp) { //sign up code
      return (
        <>
        <style>
          {`
            .product-preview {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              z-index: 99999;
            }
          `}
        </style>

<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 product-preview"  >
 <div className="flex justify-center items-center h-screen font-sans">
        <div className=" bg-white p-6 rounded-xl shadow-2xl border-2 w-[330px]">
        <h1 className="text-3xl text-center mt-2 mb-2 text-bluelogo font-semibold">Claros</h1>

        <button type="button" className="w-full border py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500  my-4 font-semi text-bluelogo" onClick={handleGoogleLogin}>
              <div className="flex items-center justify-center text-textGray font-sans">
                  <img src="/google.png" alt="Google Logo" className="h-4 w-4 mr-2" />
                  Sign up with Google
              </div>
        </button>

        <div className="flex justify-center items-center mt-6 mb-8">
          <hr className="border w-full" />
          <span className="text-center text-sm text-gray-500 px-2">or </span>
          <hr className="border w-full" />
        </div>

        <form  onSubmit={CreateAccount}>
        <div className="mt-6 mb-4 text-start">
            <input type="email" id="email" name="email" className="text-xs w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-bluelogo " placeholder="you@example.com" onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="mb-10 text-start">
            <input type="password" id="password" name="password" className="text-xs w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-bluelogo " placeholder="Password" onChange={e => setPassword(e.target.value)} />
        </div>
        <button type="submit" className="w-full bg-bluelogo text-white font-bold py-2 px-4 rounded-md font-sans">Sign up</button>
        <div className="mt-4 mb-6 text-sm">
          Already have an account? <button type="button" onClick={toggleSignUp} className="text-bluelogo">
          Sign In </button>
        </div>

        <span className="text-red-500 text-xs"> {err}</span>
        </form>
        </div>

    </div>

        </div>
</>
      )
    } else { // sign in code
      return (
      <>
      <style>
        {`
          .product-preview {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 9999;
          }
        `}
      </style>


    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 product-preview"  >
    <div className="flex justify-center items-center h-screen">
          <div className=" bg-white p-6 rounded-xl shadow-2xl border-2 w-[330px]">
          {showCloseButton && (
            <div className="flex justify-end">
              <button type="button" className="top-2 text-gray-500 hover:text-bluelogo focus:outline-none" onClick={() => {props.setShowAuthPrompt(false)}}>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          )}
          <h1 className="text-3xl text-center mt-2 mb-2 text-bluelogo font-semibold">Claros</h1>

          <button type="button" className="w-full border border-mainBorder py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500  my-4 font-semi text-bluelogo" onClick={handleGoogleLogin}>
              <div className="flex rounded-full items-center justify-center text-textGray font-sans">
                  <img src="/google.png" alt="Google Logo" className="h-4 w-4 mr-2" />
                  Continue with Google
              </div>
          </button>

          <div className="flex justify-center items-center mt-6 mb-8">
            <hr className="border w-full" />
            <span className="text-center text-sm text-gray-500 px-2">or </span>
            <hr className="border w-full" />
          </div>

          <form  onSubmit={SignIn}>
          <div className="mt-6 mb-4 text-start">
              <input type="email" id="email" name="email" className="text-xs w-full px-3 py-2 border border-gray-300 rounded-md " placeholder="you@example.com" onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="mb-4 text-start">
              <input type="password" id="password" name="password" className="text-xs w-full px-3 py-2 border border-gray-300 rounded-md" placeholder="Password" onChange={e => setPassword(e.target.value)} />
          </div>

          <div className="mb-4 text-start text-xs">
              <button type="button" onClick={resetPassword} className="text-bluelogo text-sm font-sans">
              Forgot Password?
              </button>
          </div>

          <button type="submit" className="w-full bg-bluelogo text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 font-sans ">Sign in</button>

          <span className="text-red-500 text-xs"> {err}</span>
          </form>


          <button  type="button" onClick={toggleSignUp} className="text-bluelogo mt-4 font-sans">
          No account? Sign up
          </button>
          </div>

      </div>

          </div>
    </>
      )
    }


}


