export const translationsLastChat = {
        'English': 'Thank you! I hope you enjoy your purchase. If you ever have any more questions or need help, you know where to find me! 🙂 Also if you could take a quick second to rate my performance I\'d really appreciate it!',
        'en-IN': 'Thank you! I hope you enjoy your purchase. If you ever have any more questions or need help, you know where to find me! 🙂 Also if you could take a quick second to rate my performance I\'d really appreciate it!',
        'Spanish': '¡Gracias! Espero que disfrutes de tu compra. Si alguna vez tienes más preguntas o necesitas ayuda, ¡sabes dónde encontrarme! 🙂 También si pudieras tomarte un segundo para evaluar mi desempeño, realmente lo apreciaría.',
        'French': 'Merci! J\'espère que vous apprécierez votre achat. Si vous avez d\'autres questions ou besoin d\'aide, vous savez où me trouver! 🙂 Aussi, si vous pouviez prendre une seconde pour évaluer ma performance, je l\'apprécierais vraiment.',
        'Chinese': '谢谢！我希望你喜欢你的购买。如果你以后有任何问题或需要帮助，你知道在哪里找我！🙂 另外，如果你能花一点时间为我的表现打分，我会非常感激。',
        'Hindi': 'धन्यवाद! मुझे आशा है कि आप अपने खरीददारी से खुश रहेंगे। अगर आपको कभी और सवाल हों या मदद चाहिए हो, तो आपको पता है मुझे कहाँ ढूंढना है! 🙂 अगर आप मेरे प्रदर्शन को रेट करने के लिए एक तेजी से सेकंड ले सकते हैं, तो मुझे वास्तव में पसंद आएगा।',
        'Arabic': 'شكرًا! أتمنى أن تستمتع بشراءك. إذا كان لديك أي أسئلة أخرى أو تحتاج إلى المساعدة، فأنت تعرف أين تجدني! 🙂 أيضًا إذا كنت تستطيع أخذ ثانية سريعة لتقييم أدائي، فسأكون ممتنًا حقًا.',
        'Bengali': 'ধন্যবাদ! আমি আশা করি আপনি আপনার ক্রয় উপভোগ করবেন। যদি আপনার আরও কোনো প্রশ্ন থাকে বা সাহায্য চাই, আপনি জানেন আমাকে কোথায় খুঁজতে হবে! 🙂 আপনি যদি আমার প্রদর্শন মূল্যায়ন করতে একটি দ্রুত সেকেন্ড নিতে পারেন তাহলে আমি এটি সত্যিই অনুমোদন করব।',
        'Portuguese': 'Obrigado! Espero que você aproveite sua compra. Se você tiver mais perguntas ou precisar de ajuda, sabe onde me encontrar! 🙂 Além disso, se você puder tirar um segundo rápido para avaliar meu desempenho, eu realmente agradeceria.',
        'Russian': 'Спасибо! Надеюсь, вам понравится ваша покупка. Если у вас будут еще вопросы или потребуется помощь, вы знаете, где меня найти! 🙂 Если вы могли бы уделить секунду, чтобы оценить мою работу, я был бы очень признателен.',
        'Japanese': 'ありがとう！あなたの購入を楽しんでいただけることを願っています。何か質問や助けが必要な場合は、私の居場所を知っていますね！🙂 私のパフォーマンスを評価するための少しの時間を取っていただけると、本当に感謝します。',
        'Lahnda (Western Punjabi)': 'ਧੰਨਵਾਦ! ਮੈਂ ਉਮੀਦ ਕਰਦਾ ਹਾਂ ਕਿ ਤੁਸੀਂ ਆਪਣੀ ਖਰੀਦਦਾਰੀ ਦਾ ਆਨੰਦ ਲਵੋਗੇ। ਜੇ ਤੁਹਾਨੂੰ ਹੋਰ ਕੋਈ ਸਵਾਲ ਹੋਵੇ ਜਾਂ ਮਦਦ ਚਾਹੀਦੀ ਹੋਵੇ, ਤੁਸੀਂ ਜਾਣਦੇ ਹੋ ਮੈਨੂੰ ਕਿਤੇ ਲੱਭਣਾ ਹੈ! 🙂 ਜੇ ਤੁਸੀਂ ਮੇਰੀ ਕਾਰਗੁਜ਼ਾਰੀ ਦਾ ਮੁਲਾਂਕਣ ਕਰਨ ਲਈ ਥੋੜ੍ਹੀ ਦੇਰ ਲੈ ਸਕਦੇ ਹੋ, ਮੈਂ ਇਸ ਨੂੰ ਸਚਮੁਚ ਪਸੰਦ ਕਰਾਂਗਾ।',
        'Javanese': 'Matur nuwun! Aku ngarep sampeyan seneng karo pembelianipun. Menawi sampeyan duwe pitakonan liyane utawa butuh bantuan, sampeyan ngerti endi nggoleki aku! 🙂 Uga menawi sampeyan bisa nyedhiyakake detik cepet kanggo nyathet kinerjaku, aku bener-bener ngapresiasi.',
        'German': 'Danke! Ich hoffe, Sie genießen Ihren Einkauf. Wenn Sie weitere Fragen haben oder Hilfe benötigen, wissen Sie, wo Sie mich finden! 🙂 Wenn Sie sich eine kurze Sekunde nehmen könnten, um meine Leistung zu bewerten, würde ich das sehr schätzen.',
        'Korean': '감사합니다! 구매하신 제품을 만족스럽게 사용하시길 바랍니다. 더 궁금한 점이나 도움이 필요하시면 어디서 저를 찾아야 할지 아실 겁니다! 🙂 또한, 제 성능을 평가하는데 잠시 시간을 내주시면 정말 감사하겠습니다.',
        'Telugu': 'ధన్యవాదాలు! మీరు మీ కొనుగోలును ఆనందించాలని ఆశిస్తున్నాను. మీకు మరిన్ని ప్రశ్నలు ఉంటే లేదా సహాయం కావాలి అనుకుంటే, మరింత సమాచారం కోసం నాకు ఎక్కడ తెలుసుకోవాలో తెలుసు! 🙂 మీరు నా ప్రదర్శనానికి రేటింగ్ ఇవ్వటానికి కొద్దిగా సమయం తీసుకోవచ్చు, నాకు అది చాలా ఇష్టం.',
        'Marathi': 'धन्यवाद! माझी आशा आहे की तुम्हाला तुमची खरेदी आवडेल. जर तुम्हाला अधिक प्रश्न असल्यास किंवा मदतीची गरज असल्यास, तुम्हाला माहित आहे मला कुठे सापडायचे आहे! 🙂 जर तुम्ही माझ्या कामगिरीला मूल्यांकन करण्यासाठी थोडा क्षण घेऊ शकल्यास मला खूप आनंद होईल.',
        'Turkish': 'Teşekkürler! Umarım satın aldığınız ürünü beğenirsiniz. Eğer başka sorularınız olursa veya yardıma ihtiyacınız olursa, beni nerede bulacağınızı biliyorsunuz! 🙂 Ayrıca, performansımı değerlendirebilirseniz, çok mutlu olurum.',
        'Tamil': 'நன்றி! நீங்கள் வாங்கிய பொருளை நீங்கள் ருசிக்க வேண்டும் என்று நம்புகிறேன். உங்களுக்கு மேலும் ஏதேனும் கேள்விகள் இருந்தால் அல்லது உதவி தேவைபட்டால், என்னை எங்கு காண வேண்டும் என்பது உங்களுக்கு தெரியும்! 🙂 மேலும், நீங்கள் எனது செயல்திறனை மதிப்பிட ஒரு விரைவான நோட்டா எடுக்க முடியுமா என்று நம்புகிறேன்.',
        'Urdu': 'شکریہ! میں امید کرتا ہوں آپ اپنی خریداری سے خوش ہوں گے۔ اگر آپ کو مزید کوئی سوال ہو یا مدد چاہیے ہو تو آپ جانتے ہیں کہ مجھے کہاں دھوندنا ہے! 🙂 اگر آپ میرے کارکردگی کو درجہ دینے کے لئے ایک جلدی دوسرے کو نکال سکتے ہیں تو میں اسے بہت قدر کروں گا۔',
        'Malay/Indonesian': 'Terima kasih! Saya harap anda menikmati pembelian anda. Jika anda mempunyai soalan tambahan atau memerlukan bantuan, anda tahu di mana untuk mencari saya! 🙂 Juga, jika anda boleh meluangkan sedikit masa untuk menilai prestasi saya, saya sangat menghargainya.'
    }

export const translationsDone = {
        'English': 'Done!',
        'en-IN': 'Done!',
        'Spanish': '¡Hecho!',
        'French': 'Terminé!',
        'Chinese': '完成!',
        'Hindi': 'हो गया!',
        'Arabic': 'تم!',
        'Bengali': 'সম্পন্ন!',
        'Portuguese': 'Feito!',
        'Russian': 'Готово!',
        'Japanese': '完了!',
        'Lahnda': 'ਪੂਰਾ ਹੋਇਆ!',
        'Javanese': 'Rampung!',
        'German': 'Fertig!',
        'Korean': '완료!',
        'Telugu': 'పూర్తయింది!',
        'Marathi': 'संपले!',
        'Turkish': 'Tamamlandı!',
        'Tamil': 'முடிந்தது!',
        'Urdu': 'ہو گیا!',
        'Malay/Indonesian': 'Selesai!',
    };