import { ProductButtons } from "../utils/ProductButtons";
import clsx from 'clsx';
import { useContext } from 'react';
import { PageContext } from '../../contexts/pageContext';
import posthog from "posthog-js";
import { FaShoppingCart } from "react-icons/fa";

let NUMBER_PATTERN = /\d+(\.\d+)?|\.\d+/

const extractNumber = (str, pattern) => {
    const match = str.match(pattern);
    return match ? match[0] : null;
};

const displayRatingOrReview = (data) => {
    if (typeof data === 'string') {
        return extractNumber(data, NUMBER_PATTERN);
    } else if (typeof data === 'number') {
        return data.toLocaleString();
    } else {
        return null;
    }
};

const getMerchantName = (url) => {
    try {
        const parsedUrl = new URL(url, window.location.origin);
        const hostname = parsedUrl.hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/, '');
        const domainParts = hostname.split('.');
        return domainParts.length > 1 ? domainParts[0] : hostname; // Assuming the merchant name is the first part of the domain
    } catch (error) {
        console.error("Invalid URL:", url, error);
        return "";
    }
};



export const CheckoutButton = (props) => {
    const context = useContext(PageContext)
    const hostname = props.detailPageURL ? getMerchantName(props.detailPageURL) : '';

    const showBuy = () => {
        const userEmail = props.session?.user?.email;
        if (props.forceCheckout || userEmail === "owen@ioniccommerce.com" || userEmail === "ammarsethereumdomains@gmail.com" || userEmail === "justin.d.mccarty@gmail.com" || userEmail === "justin@ioniccommerce.com" || userEmail === "anish@claros.so") {
            return true;
        } else {
            return false;
        }
    }

    const handleBuyNow = (link) => {
        posthog.capture('product click', { product: link, click_type: 'buy now', modal: props.toggleOpened || false, merchant_button: true })
        // End of the Selection
        if (props.toggleOpened) {
            props.toggle()
        }
        props.setCheckoutProduct(null); // Temporarily set to null to ensure re-trigger
        setTimeout(() => {
            props.setCheckoutProduct(props.productInfo);
        }, 0); // Introduce a delay
    }

    return showBuy() ? (
        <div className={`flex flex-row items-center justify-between bg-white p-2 my-2 ${props.border ? 'border border-gray-200 shadow-sm rounded-lg' : ''} `}>
            <div className="flex items-center space-x-2">
                <img src={`/logo-circle.png`} alt="favicon" className='w-6 h-6' />
                <div className="flex flex-col">
                    <span className={`text-sm ${props.classic ? '' : 'font-medium'}`}>Claros</span>
                    {/* <div className="flex items-center space-x-1">
                        {props.rating && (
                            <span className="text-yellow-400">
                                ★ {displayRatingOrReview(props.rating)}
                            </span>
                        )}
                        {props.reviews_count && (
                            <span className="text-xs text-gray-500">
                                ({displayRatingOrReview(props.reviews_count)} reviews)
                            </span>
                        )}
                    </div> */}
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <span className={`text-lg ${props.classic ? '' : 'font-medium'} text-gray-800`}>{props.price}</span>
                <button onClick={()=> handleBuyNow(props.link)}  rel="noopener noreferrer" className={`${props.classic ? 'bg-cardBg text-textGray hover:bg-zinc-200' : 'bg-indigo-500 hover:bg-indigo-600 text-white font-bold'} py-2 px-4 rounded inline-block text-sm`}>
                    <FaShoppingCart className="inline mr-2" />
                    Buy Now
                </button>
            </div>
        </div>
    ) : null;
}
