import { useState, useContext } from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../@/components/ui/tabs"
import { SourcesSection } from "./Sources"
import { PiListMagnifyingGlassLight, PiTableLight, PiBooksLight, PiFlaskDuotone } from 'react-icons/pi';
import markdownToHTML from '../../utils/CarouselMarkdowntoHTML';
import { ComparisonTable } from './ComparisonTable';
import cn from 'classnames';
import { ReviewSummary } from "./ReviewSummary";
import { IngredientAnalysis } from "./IngredientAnalysis";
import { PageContext } from "../../../contexts/pageContext";
import posthog from 'posthog-js';


const MoreInfoTabTrigger = ({ children, className, value, ...props }) => (
  <TabsTrigger
    value={value}
    className={cn(
      "relative rounded-none border-b-2 bg-transparent px-4 pb-3 pt-2 text-zinc-700 text-muted-foreground shadow-none transition-none data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none",
      className
    )}
    onClick={() => posthog.capture('More_Info_Clicked', { value })}
    {...props}
  >
    {children}
  </TabsTrigger>
);

export const MoreInfoTabs = ({redditRefs, videos, gl, productInfo, searchQuery, askedQuestions}) => {

    const context = useContext(PageContext)
    const [table, setTable] = useState("");
    const [reviews, setReviews] = useState("")
    const [analysis, setAnalysis] = useState("");


    return (
        <>
        <Tabs defaultValue="sources" className="">
        <TabsList className=" flex space-x-2 rounded-xl p-2">
          {context.id == "supplements" &&
            <MoreInfoTabTrigger value="ingredients" className="">
                <PiFlaskDuotone className={'mr-2 font-bold'} size={20}/> Ingredients
            </MoreInfoTabTrigger>
          }
          {/* <MoreInfoTabTrigger value="sources" className="">
            <PiBooksLight className={'mr-2 font-bold'} size={20}/> Sources
          </MoreInfoTabTrigger> */}
          {/* <MoreInfoTabTrigger value="comparison" className="">
            <PiTableLight className='mr-2' size={20}/> Compare
          </MoreInfoTabTrigger> */}
          <MoreInfoTabTrigger value="reviews" className="">
            <PiListMagnifyingGlassLight className='mr-2' size={20}/> Reviews
          </MoreInfoTabTrigger>
        </TabsList>
        {/* <TabsContent value="sources">
            <SourcesSection redditRefs={redditRefs} videos={videos} loaderVisible={true} />
        </TabsContent> */}
        {/* <TabsContent value="comparison">
            <ComparisonTable
                sortedProducts={sortedProducts}
                searchQuery={searchQuery}
                askedQuestions={askedQuestions}
                setTable={setTable}
                table={table} />
        </TabsContent> */}
        <TabsContent value="reviews">
            <ReviewSummary
                productInfo={productInfo}
                redditRefs={redditRefs}
                askedQuestions={askedQuestions}
                searchQuery={searchQuery}
                gl={gl}
                reviews={reviews}
                setReviews={setReviews}
            />
        </TabsContent>
        <TabsContent value="ingredients">
            <IngredientAnalysis
                productInfo={productInfo}
                analysis={analysis}
                setAnalysis={setAnalysis}
            />
        </TabsContent>
        </Tabs>


        {/* <div className="flex justify-start space-x-4 mt-6 text-sm">
        //   { context.id == "supplements" &&
        //   <button onClick={ingredientHandler} className={`text-xs  md:text-[15px] flex items-center py-2 px-2 md:px-4 rounded-md font-gilroy-regular bg-bgcard  ${ingredientAnalysisVisible ? 'bg-slate-3' : ''}`}>
        //     <PiFlaskDuotone className='mr-2' size={20}/>Ingredient Analysis {ingredientAnalysisVisible ? <FaChevronUp className='ml-2 text-textGray' size={10}/> : <FaChevronDown className='ml-2 text-textGray' size={10}/>}
        //     {showSpinner && ingredientAnalysisVisible && <FaSpinner className="animate-spin ml-2 text-gray-500" size={15}/>}
        //   </button>
        //   }
        //   <button onClick={tableHandler} className={`text-xs  md:text-[15px] flex items-center py-2 px-2 md:px-4  rounded-md font-gilroy-regular bg-bgcard  ${tableVisible ? 'bg-slate-3' : ''}`}>
        //     <PiTableThin className='mr-2' size={20}/>  Product Comparison  {tableVisible ? <FaChevronUp className='ml-2 text-textGray' size={10}/> : <FaChevronDown className='ml-2 text-textGray' size={10}/>}
        //     {showSpinner && tableVisible && <FaSpinner className="animate-spin ml-2 text-gray-500" size={15}/>}
        //   </button>
        //   <button onClick={ReviewsHandler} className={`text-xs md:text-[15px] flex items-center py-2 px-2 md:px-4  rounded-md font-gilroy-regular bg-bgcard ${ReviewsVisible ? 'bg-slate-3' : ''}`}>
        //     <PiListMagnifyingGlassThin className='mr-2' size={20}/>Research Reviews {ReviewsVisible ? <FaChevronUp className='ml-2 text-textGray' size={10}/> : <FaChevronDown className='ml-2 text-textGray' size={10}/>}
        //     {showSpinner && ReviewsVisible && <FaSpinner className="animate-spin ml-2 text-gray-500" size={15}/>}
        //   </button>
        // </div>

        // { context.id == "supplements" && ingredientAnalysis && ingredientAnalysisVisible &&
        // <div className='mt-8 w-full'>
        //   <div className='flex items-center justify-start w-full text-xl pt-6 text-textMain pb-6 tracking-normal'>
        //     <PiFlaskDuotone className='mr-2' size={25}/>  Ingredient Analysis
        //   </div>
        //   <div className='flex justify-center items-center w-full'>

        //     <div dangerouslySetInnerHTML={{ __html: markdownToHTML(ingredientAnalysis) }} ></div>

        //   </div>
        // </div>
        // } */}


        </>
    )
}